import React from 'react';
import { Icon } from './Icon';
import { IconSet } from './types';

import './icon-set.scss';

export const IconSetComponent = (props: IconSet) => {
  return (
    <div className='container pt-5 pb-5 icon-set__container'>
      <div className='row'>
        <div className='column eyebrow mb-3 mb-md-5 text-uppercase text-center'>
          { props.eyebrow }
        </div>
      </div>
      <div className='row'>
        { props.icons.map((icon, index) => {
            return (
              <div className='icon-set__icon col col-12 col-md-4' key={icon.headline}>
                <Icon {...icon} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
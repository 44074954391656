import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { CallToActionButton } from "../call-to-action-button/CallToActionButton";
import { HeroContentfulProps } from "../hero/types";
import { useHeroAttributes } from "../hero/useHeroAttributes";

import './feature-product-hero.scss';

export const FeatureProductHero = (props: HeroContentfulProps) => {
  const {
    headline,
    subheadline,
    backgroundImage,
  } = useHeroAttributes(props);
  return (
    <div className="container-fluid feature-product-hero">
      <div className="row flex-column-reverse flex-md-row p-3">
        <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-center align-self-center">
          <div className='d-flex flex-column align-items-center'>
            <div className='text-center'>
              { headline }
            </div>
            <div className='text-center'>
              { subheadline }
            </div>
            { props.callToAction && <CallToActionButton {...props.callToAction} /> }
          </div>
        </div>
        <div className="col-md-6 col-sm-12 d-flex justify-content-center p-3 p-md-0">
          <GatsbyImage
            style={{
              boxShadow: "-14px 4px 44px -4px rgba(112,108,112,1)"
            }}
            alt=""
            image={getImage(backgroundImage)}
          />
        </div>
      </div>
    </div>
  );
};

import { getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { HeroProps, HeroContentfulProps, HERO_RENDER_OPTIONS } from './types';

export function useHeroAttributes(props: HeroContentfulProps): HeroProps {
  const backgroundImage = getImage(props.backgroundImage);
  const headline = renderRichText(props.headline);
  
  const hero = {
    backgroundImage,
    headline,
    subheadline: null,
  };

  if (props.subheadline) {
    hero.subheadline = renderRichText(props.subheadline, HERO_RENDER_OPTIONS);
  }

  return hero;
}
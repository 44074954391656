import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
  renderRichText,
} from "gatsby-source-contentful/rich-text";
import React from "react";
import { SimpleInputForm } from "../simple-input-form/SimpleInputForm";
import { SimpleInputFormProps } from "../simple-input-form/types";
import "./CallToAction.scss";

const CallToAction = ({
  headline,
  subheadline,
  backgroundImage,
  hasInputForm,
  simpleInputForm,
}: {
  headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  subheadline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  backgroundImage: any;
  hasInputForm: boolean;
  simpleInputForm: SimpleInputFormProps,
}) => {
  return (
    <div
      className={`call-to-action-wrapper ${backgroundImage && "has-bg-img"}`}
    >
      {backgroundImage && (
        <GatsbyImage
          style={{ gridArea: "1/1" }}
          alt="Call To Action"
          image={getImage(backgroundImage)}
        />
      )}
      <div className="container call-to-action-container pt-5 pb-4">
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className="section-title text-center">
              <h3>{ renderRichText(headline) }</h3>
              <div>
                { renderRichText(subheadline) }
              </div>
              <div className="call-to-action-__button-container">
                { hasInputForm && <SimpleInputForm {...simpleInputForm} /> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;

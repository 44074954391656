import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import './Hero.scss';
import { HeroContentfulProps } from "./types";
import { useHeroAttributes } from "./useHeroAttributes";

const Hero = (props: HeroContentfulProps): React.ReactElement => {
  const {
    backgroundImage,
    subheadline,
    headline,
  } = useHeroAttributes(props);
  return (
    <div
      className="hero slider-area"
      style={{ 
        display: "grid",
       }}
    >
      <GatsbyImage
        style={{
          gridArea: "1/1",
        }}
        alt=""
        image={backgroundImage}
      />
      <div
        className="container height-100"
        style={{
          gridArea: "1/1",
          position: "relative",
        }}
      >
        <div className="Hero_text-wrapper z-index">
          <div className="Hero_text-inner">
          { headline &&
              <h1 className="text-white Hero_headline">
                {headline}
              </h1>
            }
            { subheadline && <h3 className="Hero_subheadline">{ subheadline }</h3> }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';
import { marked } from "marked";
import { HeaderBodyIcon } from "./types";

import './icon.scss';

export const Icon = (props: HeaderBodyIcon) => {
  return (
    <div className="icon__container d-flex flex-column align-items-center text-center">
      <img height='72' width='72' src={props.icon.file.url} alt='' />
      <h3> { props.headline } </h3>
      { props.markdownContent && <div dangerouslySetInnerHTML={{__html: marked(props.markdownContent.markdownContent)}} /> }
    </div>
  );
}
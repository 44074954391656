import { Link } from "gatsby";
import React from "react";

export const CallToActionButton = (props: {
  linkText: string;
  buttonText: string;
}) => {
  const internal = /^\/(?!\/)/.test(props.linkText)
  return (
    <div className='d-grid col-6'>
      {
        internal ?
        <Link className="btn btn-primary" to={props.linkText}>
          {props.buttonText}
        </Link>
        :
        <a className="btn btn-primary" href={props.linkText}>
          { props.buttonText }
        </a>
      }
    </div>
  );
};

import { RenderRichTextData, ContentfulRichTextGatsbyReference } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { IGatsbyImageData } from "gatsby-plugin-image";

export interface HeroContentfulProps {
  headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  subheadline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  backgroundImage: IGatsbyImageData;
  callToAction: {
    linkText: string;
    buttonText: string;
  }
};

export interface HeroProps {
  headline: React.ReactNode;
  subheadline: React.ReactNode;
  backgroundImage: IGatsbyImageData;
};
export const HERO_RENDER_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return children;
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return children;
    }
  },
};
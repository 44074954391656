import { graphql } from "gatsby";
import React from "react";
import { componentMappings } from "../components";

import "bootstrap/scss/bootstrap.scss";
import NavBar from "../components/nav/NavBar";
import Footer from "../components/footer/Footer";

import "./index.scss";

const ContentfulPage = (props: any): React.ReactElement<any> => {
  return (
    <>
      <NavBar />
      {props.data.contentfulPage.pageComponents.map((pageComponent, index) => {
        const DynamicComponent = componentMappings.get(
          pageComponent.__typename
        );
        return (
          <DynamicComponent
            key={`${pageComponent.__typename}_${index}`}
            {...pageComponent}
          />
        );
      })}
      <Footer />
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    contentfulPage(id: { eq: $id }) {
      title
      pageComponents {
        ... on ContentfulHero {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          backgroundImage {
            gatsbyImageData(height: 500)
          }
        }

        ... on ContentfulFeatureProductHero {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          backgroundImage {
            gatsbyImageData(height: 450)
          }
          callToAction {
            ... on ContentfulCallToActionButton {
              linkText
              buttonText
            }
          }
        }

        ... on ContentfulOverview {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          imageRight
          image {
            gatsbyImageData
          }
          markdownContent {
            markdownContent
          }
          hasInsetImage
          hasGreyBackground
          callToAction {
            buttonText
            linkText
          }
        }

        ... on ContentfulIconSet {
          __typename
          eyebrow
          icons {
            ... on ContentfulHeaderBodyAndIcon {
              icon {
                file {
                  url
                }
              }
              headline
              markdownContent {
                markdownContent
              }
            }
          }
        }

        ... on ContentfulAccordion {
          __typename
          heading: headline
          panels {
            ... on ContentfulAccordionPanel {
              heading
              body {
                raw
              }
            }
          }
        }

        ... on ContentfulCallToAction {
          __typename
          headline {
            raw
          }
          subheadline {
            raw
          }
          backgroundImage {
            gatsbyImageData(height: 400)
          }
          hasInputForm
          simpleInputForm {
            submitUrl
            buttonText
            inputFieldName
            inputFieldType
            successText {
              successText
            }
          }
        }
      }
    }
  }
`;

export default ContentfulPage;

import { IconSetComponent } from './icon-set/IconSet';
import { FeatureProductHero } from './feature-product-hero/FeatureProductHero';
import React from "react";
import Accordion from "./accordion/Accordion";
import ArticleCardSet from "./article/ArticleCardSet";
import CallToAction from "./call-to-action/CallToAction";
import Hero from "./hero/Hero";
import NavBar from "./nav/NavBar";
import Overview from "./overview/Overview";

export const componentMappings = new Map<
  string,
  (props: any) => React.ReactElement
>([
  ["ContentfulAccordion", Accordion],
  ["ContentfulArticleCardSet", ArticleCardSet],
  ["ContentfulCallToAction", CallToAction],
  ["ContentfulFeatureProductHero", FeatureProductHero],
  ["ContentfulHero", Hero],
  ['ContentfulIconSet', IconSetComponent],
  ["ContentfulNavBar", NavBar],
  ["ContentfulOverview", Overview],
]);

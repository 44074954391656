import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RenderRichTextData, ContentfulRichTextGatsbyReference, renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import { marked } from "marked";

import './Overview.scss';
import { Link } from "gatsby";

const Overview = ({
  headline,
  subheadline,
  imageRight,
  image,
  markdownContent,
  hasInsetImage,
  hasGreyBackground,
  callToAction,
}: {
  headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  subheadline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  imageRight: boolean;
  image: any;
  markdownContent: any;
  hasInsetImage: boolean;
  hasGreyBackground: boolean;
  callToAction: { buttonText: string; linkText: string; };
}) => {
  return (
    <div className={`overview__container p-0 ${hasGreyBackground && 'gray-bg'}`}>
      <div className="container-fluid">
        <div className={`row ${imageRight ? 'flex-row-reverse' : ''}`}>
          <div className={`col-md-6 ${hasInsetImage ? 'p-5' : 'p-0'}`}>
            <div
              className="overview__image-container"
            >
              <GatsbyImage 
                alt=''
                image={getImage(image)}
              />
            </div>
          </div>
          <div className="col-md-6 text-wrapper">
            <div className="info-details p-3 p-lg-5">
              <div className="mb-20">
              { renderRichText(headline) }
              </div>

              { subheadline && renderRichText(subheadline) }

              { markdownContent && <div dangerouslySetInnerHTML={{__html: marked(markdownContent.markdownContent)}} /> }
              { callToAction && <Link className='btn btn-primary' to={callToAction.linkText}>{ callToAction.buttonText}</Link> }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;


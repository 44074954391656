import axios from "axios";
import React, { useState } from "react";
import { SimpleInputFormProps } from "./types";

export const SimpleInputForm = (props: SimpleInputFormProps) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (e.target.checkValidity()) {
      try {
        const value = e.target[props.inputFieldName].value;
        await axios.post(props.submitUrl, {
          [props.inputFieldName]: value,
        });
        setSuccess(true);
      } catch (error) {
        
      }
    }
    setLoading(false);
  };
  return !success ? (
    <form onSubmit={handleSubmit}>
      <div className="row justify-content-center">
        <div className="col-8 control">
          <label className="visually-hidden">{ props.inputFieldName }</label>
          <input
            className="form-control"
            aria-label={`Enter ${props.inputFieldType}`}
            name={props.inputFieldName}
            type={props.inputFieldType}
            placeholder={`Enter your ${props.inputFieldName}`}
          />
        </div>
        <div className="col-auto control">
          <button
            type="submit"
            className="btn btn-primary"
          >
            {
              loading ?
                <>
                  <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                  Loading...
                </>
              :
              <>{ props.buttonText }</>
            }
          </button>
        </div>
      </div>
    </form>
  ) : (
    <div className="notification">
      {
        props.successText && (
          <p>
            Thanks for the submission!  We successfully received it!
          </p>
        )
      }
    </div>
  );
};

import React from "react";
import { ArticleProps } from "./Article";

import ArticleCard from "./ArticleCard";

import './ArticleCardSet.scss';

const ArticleCardSet = (props) => {
  return (
    <div className="ArticleCardSet container-md">
      <div className="row">
        <div className="col ArticleCardSet_eyebrow">
          { props.eyebrow }
        </div>
      </div>
      <div className="row ArticleCardSet_card-row flex-nowrap">
        {
          props.articles.map((article: ArticleProps) =>
            <div className="col-5 col-md-4" key={article.headline}>
              <ArticleCard article={article} />
            </div>
          )
        }
      </div>
    </div>
  );
};

interface ArticleCardSetProps {
  eyebrow: string;
  articles: ArticleProps[];
}

export default ArticleCardSet;
